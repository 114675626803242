<template>
  <GenericSideMenu
    class="NotifyModal"
    :position="'right'"
    :title="$t('notify.modal.title')"
    @close="uiStore.closeAllModal()"
  >
    <div v-if="!done">
      <div class="type-sm mb-12">
        {{ $t('notify.modal.text') }}
      </div>
      <Form
        @submit="onSubmit"
        @click="startValidate"
      >
        <Field
          v-slot="{ field, meta }"
          name="email"
          :rules="validateEmail"
          :value="userStore.isLoggedIn ? userStore.userProfile.email : ''"
        >
          <input
            type="email"
            autocomplete="email"
            class="input mb-12"
            v-bind="field"
            :placeholder="$t('productPage.stockAlertPlaceholder')"
            :class="{
              'invalid': meta.validated && !meta.valid
            }"
          >
        </Field>
        <ErrorMessage name="email" class="text-criticalDark type-sm-medium leading-single block mb-12" />
        <button class="btn w-full" :disabled="loading">
          {{ $t('notify.modal.button') }}
        </button>
      </Form>
    </div>
    <div v-else class="type-sm">
      {{ successText }}
    </div>
  </GenericSideMenu>
</template>

<script setup lang="ts">
import { Form, Field, ErrorMessage } from 'vee-validate';
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import useApiFetch from '~/composeables/useApiFetch';
import useValidation from '~/composeables/useValidation';

const {
  startValidate,
  validateEmail,
} = useValidation();

const { $t } = useNuxtApp();
const uiStore = useUiStore();
const userStore = useUserStore();
const { apiPost, lastResponseCode } = useApiFetch();
const loading = ref(false);
const done = ref(false);
const successText = ref<string | null>(null);

const onSubmit = async(values: any) => {
  loading.value = true;
  const res = await apiPost(`/products/${uiStore.notifyModalPartNo}/stock-alerts`, {
    email: values.email,
  });
  if (res) {
    done.value = true;
    successText.value = lastResponseCode.value === 201 ? $t('productPage.stockAlertSuccess') : $t('productPage.stockAlertSuccessAgain');
  }
  loading.value = false;
};
</script>

<style scoped lang="postcss">
::placeholder {
  @apply absolute top-[14px];
}
input.input {
  @apply pt-0;
}
</style>
