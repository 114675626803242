<template>
  <GenericSideMenu
    position="right"
    class="LogInAsCustomerModal"
    content-class="!p-16 !pt-0"
    :title="`${$t('mypage.customer.seeAsCustomer')}`"
    @close="close"
  >
    <LogInAsCustomer @close="close" />
  </GenericSideMenu>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import LogInAsCustomer from '~/components/account/LogInAsCustomer.vue';
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();

const close = () => {
  uiStore.closeAllModal();
};

</script>
