<template>
  <div
    :class="{
      'bg-lighter': isGrayPage,
    }"
    class="default-vue"
  >
    <transition name="fromLeft">
      <SideMenu v-if="showSideMenu" />
    </transition>
    <transition name="fromRight">
      <MiniCart v-if="showMiniCart" />
    </transition>
    <transition name="fromRight">
      <QuickBuy v-if="showQuickBuy" />
    </transition>
    <transition :name="isMobile ? 'fromBottom' : 'fromRight'">
      <AddedToCart
        v-if="showAddedToCart"
        :is-scrolled="isScrolled"
        :scroll-pos="scrollPos"
      />
    </transition>
    <transition name="fromRight">
      <DeclineOrderProposalModal v-if="showDeclineOrderProposalModal" />
    </transition>
    <transition name="fromRight">
      <EditProductDiscountModal v-if="showEditProductDiscountModal" />
    </transition>
    <transition name="fromRight">
      <SigninModal v-if="showSigninModal" />
    </transition>
    <transition name="fromRight">
      <ImpersonateModal v-if="showImpersonateModal" />
    </transition>
    <transition name="fromRight">
      <LogInAsCustomerModal v-if="showLogInAsCustomer" />
    </transition>
    <transition name="fromRight">
      <NotifyModal v-if="showNotify" />
    </transition>
    <transition name="fade">
      <BarcodeScanner
        v-if="showScanner"
        @close="uiStore.setShowScanner(false)"
        @add-products="cartStore.addMultipleItemsToCart"
      />
    </transition>
    <transition name="fade">
      <div
        v-if="showModalBg"
        class="modalBg modalHeight"
        @click="uiStore.closeAllModal()"
      />
    </transition>
    <transition name="fadeSlowOut">
      <TempError v-if="uiStore.tempErrorMsg" />
    </transition>

    <ImpersonatingNotice v-if="userStore.userProfile.impersonatingCompany" />
    <PointshopReminder v-else-if="userStore.isLoggedIn && pointShopReminder && !isCheckout" :to="pointShopReminder" />
    <SiteNotice v-else-if="!isCheckout" :settings="globalContentStore.siteNotice as StoryBlokSiteNoticeData" />
    <CheckoutHeader v-if="isCheckout" />
    <PageHeader v-else />
    <DesktopMenu
      v-if="!isCheckout && !uiStore.isMobile"
      :key="`DesktopMenu${userStore.isLoggedIn ? 'loggedIn':''}`"
    />
    <MobileSearch v-if="!isCheckout" />
    <main class="main relative">
      <slot />
    </main>
    <transition name="fadeSlowOut">
      <TempMsg v-if="uiStore.tempMsg" />
    </transition>
    <PageFooter v-if="!isCheckout" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, watch } from 'vue';
import PageFooter from '~/components/body/PageFooter.vue';
import PageHeader from '~/components/body/PageHeader.vue';
import SideMenu from '~/components/menu/SideMenu.vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import MobileSearch from '~/components/search/MobileSearch.vue';
import SiteNotice from '~/components/body/SiteNotice.vue';
import ImpersonatingNotice from '~/components/body/ImpersonatingNotice.vue';
import DesktopMenu from '~/components/menu/DesktopMenu.vue';
import QuickBuy from '~/components/product/QuickBuy.vue';
import MiniCart from '~/components/cart/MiniCart.vue';
import AddedToCart from '~/components/cart/AddedToCart.vue';
import CheckoutHeader from '~/components/body/CheckoutHeader.vue';
import SigninModal from '~/components/account/SigninModal.vue';
import ImpersonateModal from '~/components/account/ImpersonateModal.vue';
import LogInAsCustomerModal from '~/components/account/LogInAsCustomerModal.vue';
import DeclineOrderProposalModal from '~/components/account/DeclineOrderProposalModal.vue';
import EditProductDiscountModal from '~/components/checkout/EditProductDiscountModal.vue';
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import NotifyModal from '~/components/product/NotifyModal.vue';
import TempError from '~/components/body/TempError.vue';
import TempMsg from '~/components/body/TempMsg.vue';
import { StoryBlokSiteNoticeData } from '~/constants/types/storyblok';
import useCheckScroll from '~/composeables/useCheckScroll';
import PointshopReminder from '~/components/checkout/PointshopReminder.vue';
import BarcodeScanner from '~/components/fastOrder/BarcodeScanner.vue';

const uiStore = useUiStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const globalContentStore = useGlobalContentStore();
const { isScrolled, scrollPos } = useCheckScroll();

const { showScanner, showSigninModal, showSideMenu, showQuickBuy, showMiniCart, showModalBg, showAddedToCart, showNotify, isMobile, showDeclineOrderProposalModal, showEditProductDiscountModal, showImpersonateModal, showLogInAsCustomer } = storeToRefs(uiStore);

const route = useRoute();
const isCheckout = ref(route.name?.toString().endsWith('checkout') || route.name?.toString()?.endsWith('checkout-pointshop-basketId'));
const isGrayPage = ref(false);

const pointShopReminder = useCookie('pointShopReminder');

const checkGrayPage = () => {
  const name = route.name?.toString() ?? '';
  isGrayPage.value = (
    (name.includes('checkout') || name.includes('account') || name.includes('salestool'))
      && name !== 'lang-account-resetpassword-email-token'
      && name !== 'lang-account-signin'
      && name !== 'lang-account-invite'
  );
};
checkGrayPage();

// Creates a css variable for 100vh that works on ios safari
const setWindowHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

// Detects mobile
const updateWidth = () => {
  uiStore.setIsMobile(window.innerWidth < 1024); //
  uiStore.setisTabletPortrait(window.innerWidth > 640 && window.innerWidth < 1024);
};

watch(showModalBg, (newVal)=> {
  if (newVal) {
    document.body.classList.add('noScroll');
  } else {
    document.body.classList.remove('noScroll');
  }
});
watch(() => route.name, (newVal)=> {
  isCheckout.value = (newVal?.toString()?.endsWith('checkout') || route.name?.toString()?.endsWith('checkout-pointshop-basketId'));
  checkGrayPage();
});

onMounted(()=> {
  setWindowHeight();
  window.addEventListener('resize', setWindowHeight);
  updateWidth();
  window.addEventListener('resize', updateWidth);

  // Settimeout needed here
  setTimeout(() => {
    /**
     * Load cart
     * If this is a checkout success page or pointshop, that page will take care of cartloading
     */
    if (
      !((route.name === 'lang-checkout-result-cartId' && route.params.result === 'success') || route.name === 'lang-checkout-pointshow-basketId')
    ) {
      cartStore.getCart(false);
    }
    userStore.loadUserPrices();
  }, 200);
});
onUnmounted(() => {
  window.removeEventListener('resize', setWindowHeight);
  window.removeEventListener('resize', updateWidth);
  document.body.classList.remove('noScroll');
});

</script>

<style lang="postcss">
/**
Makes the footer just visible on empty page
 */
.main {
  min-height: calc(var(--vh, 1vh) * 100 - 275px);
}
</style>
