<template>
  <div class="SideMenuContent modalHeight w-window bg-lightest overflow-hidden flex flex-col">
    <!-- * * * * * * head -->
    <div class="flex w-full items-center justify-between h-64 basis-64 shrink-0 px-16 border-b border-light">
      <div
        v-if="!isTopParent"
        class="w-24 basis-[24px] shrink-0 cursor-pointer"
      >
        <img
          src="/icons/arrow-left.svg"
          class="w-24 h-24 "
          @click="closeCurrent"
        >
      </div>
      <div
        v-if="!isTopParent"
        class="basis-full type-headline-2xl leading-single whitespace-nowrap px-16"
      >
        {{ menuName }}
      </div>
      <div v-else class="type-headline-2xl leading-single whitespace-nowrap pr-16">
        {{ $t('header.menu') }}
      </div>
      <div
        class="w-24 basis-24 shrink-0 cursor-pointer text-right"
        @click="closeMenu"
      >
        <img src="/icons/close.svg" class="w-24 h-24">
      </div>
    </div>

    <div class="overflow-hidden overflow-y-auto">
      <!-- * * * * * * primary menu -->
      <div
        v-if="parentMenu?.mobileButton && parentMenu?.link && parentMenu.mobileButton !== ''"
        class="w-full h-64 px-16
          border-b border-light
          flex items-center justify-between
          "
      >
        <nuxt-link
          class="btn--text"
          :to="$sbLinkFix(parentMenu?.link)"
          :target="$sbLinkTarget(parentMenu?.link)"
        >
          {{ parentMenu?.mobileButton }}
        </nuxt-link>
      </div>
      <div v-if="parent === 'selectCountry'" class="mt-12">
        <nuxt-link
          v-for="country in globalContent.countryDomains"
          :key="country.countryCode"
          :to="country.countryUrl"
          class="flex mb-16 items-center"
        >
          <div class="h-24 w-24 mr-12">
            <img
              :src="country.flagUrl"
              :alt="$t(`country.${country.countryCode}`)"
              class="h-24 w-24"
            >
          </div>
          <div
            class="mb-2"
            :class="{
              'type-xs-medium': globalContent.getCountryCode === country.countryCode,
              'type-xs': globalContent.getCountryCode !== country.countryCode,
            }"
          >
            {{ $t(`country.${country.countryCode}`) }}
          </div>
        </nuxt-link>
      </div>
      <div v-else-if="parentMenu?.showAllChildren">
        <div
          v-for="link in content"
          :key="link._uid"
          class="border-b border-light type-sm"
        >
          <div class="type-headline-lg px-16 py-18">{{ link.title }}</div>
          <nuxt-link
            v-for="sublink in link.children"
            :key="sublink._uid"
            class="block px-16 mb-18"
            :to="$sbLinkFix(sublink.link)"
            :target="$sbLinkTarget(sublink.link)"
          >
            {{ sublink.title }}
          </nuxt-link>
        </div>
      </div>
      <div v-else>
        <div
          v-for="link in content"
          :key="link._uid"
          class="w-full h-64 px-16
          border-b border-light
          flex items-center justify-between
          leading-single"
          :class="{
            'text-criticalDark': checkDesign(link.design, 'sale'),
            'type-tagline-lg': isTopParent,
            'type-sm': !isTopParent,
          }"
          @click="clickMenu($sbLinkFix(link.link), (link.children.length > 0), link._uid)"
        >
          <div v-if="link.icon && link.icon.filename" class="basis-24">
            <img
              :src="link.icon.filename"
              class="w-24 h-24"
              :alt="link.title"
            >
          </div>
          <div
            class="basis-full fixBaseline"
            :class="{
              'pl-12': link.icon?.filename
            }"
          >
            {{ link.title }}
          </div>
          <MenuItemFavorite
            v-if="!userStore.isCustomerSuccessUser"
            :item="link"
            :hide-title="true"
          />

          <div v-if="link.children.length" class="basis-24">
            <img
              src="/icons/angle-right.svg"
              class="h-24 w-24"
            >
          </div>
        </div>
      </div>

      <!-- * * * * * * secondary menu -->
      <div v-if="parent === '' && secondary.length" class="">
        <div
          v-for="link in secondary"
          :key="link._uid"
          class="w-full h-64 px-16
          border-b border-light
          flex items-center justify-between
          type-sm leading-single"
          :class="{
            'text-criticalDark': checkDesign(link.design, 'sale'),
          }"
          @click="clickMenu($sbLinkFix(link.link), (link.children.length > 0), link._uid)"
        >
          <div>{{ link.title }}</div>
          <div v-if="link.children.length">
            <img
              src="/icons/angle-right.svg"
              class="h-16"
            >
          </div>
        </div>
      </div>

      <!-- * * * * * * Fixed links menu -->
      <div
        v-if="parent === ''"
        class="mb-64"
      >
        <button
          class="w-full h-64 px-16
          border-b border-light
          flex items-center justify-start
          type-sm-medium leading-single"
          @click="myPage()"
        >
          <img
            :src="isSeller ? '/icons/laptop-performance.svg' : '/icons/mypage.svg'"
            class="w-20 h-20 mr-12 inline-block"
            :alt="isSeller ? $t('header.sellerTools') : $t('header.myPage')"
          >
          <div class="fixBaseline">{{ myPagesText }}</div>
        </button>

        <!-- country selector, keep for future use
        <div class="flex mb-16 cursor-pointer" @click="clickMenu(null, null, 'selectCountry')">
          <div class="h-24 w-24 mr-8">
            <img
              :src="globalContent.getFlagUrl"
              :alt="$t(`country.${globalContent.getCountryCode}`)"
              class="h-24 w-24"
            >
          </div>
          <div>{{ $t(`country.${globalContent.getCountryCode}`) }}</div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { StoryBlokNestableNav } from '~/constants/types/storyblok';
import { useUiStore } from '~/store/ui';
import useMenuHelper from '~/composeables/useMenuHelper';
import { useUserStore } from '~/store/user';
import MenuItemFavorite from '~/components/menu/MenuItemFavorite.vue';
const uiStore = useUiStore();
const globalContent = useGlobalContentStore();
const mainNavigation = globalContent.getNavigation('mainNavigation');
const secondary = globalContent.getNavigation('secondaryNavigation');
const userStore = useUserStore();
const { $t } = useNuxtApp();
const props = defineProps<{
  parent: string,
  isTopParent: boolean,
  depth?: number,
}>();

let content = [] as StoryBlokNestableNav[];
let menuName = 'Menu';

let parentMenu = null as StoryBlokNestableNav | null;

if (props.parent === '') {
  content = mainNavigation;
} else if (props.parent === 'selectCountry') {
  menuName = $t('country.selectCountry');
} else {
  const combined = mainNavigation.concat(secondary);
  const submenu = globalContent.getNavByUid(props.parent, combined);
  if (submenu) {
    parentMenu = submenu;
    content = submenu.children;
    menuName = submenu.title;
  }
}
const router = useRouter();
const clickMenu = (url: string | null, children: boolean | null, _uid: string) => {
  if (children || _uid === 'selectCountry') {
    uiStore.addSideMenuParent(_uid);
  } else {
    router.push({ path: `${url}` });
    uiStore.closeSideMenu();
  }
};

const closeCurrent = () => {
  uiStore.closeTopSideMenu();
};
const closeMenu = () => {
  uiStore.closeSideMenu();
};

const { checkDesign } = useMenuHelper();

const isSeller = computed(() => {
  return userStore.isSalesRepUser;
});

const isCustomerSuccess = computed(() => {
  return userStore.isCustomerSuccessUser;
});

const myPagesText = computed(() => {
  if (isSeller.value) {
    return $t('header.sellerTools');
  } else if (isCustomerSuccess.value) {
    return userStore.userProfile.impersonatingCompany ? userStore.userProfile.company.name : $t('header.customerSuccessImpersonate');
  } else if (userStore.isLoggedIn) {
    return userStore.userProfile.company.name;
  } else {
    return $t('header.myPage');
  }
});

const myPage = () => {
  if (isCustomerSuccess.value && !userStore.userProfile.impersonatingCompany) {
    uiStore.setShowLogInAsCustomer(true);
  } else {
    uiStore.setShowSigninModal(true);
  }
};

onMounted(()=> {
  globalContent.loadBrands();
});

</script>

<style scoped lang="postcss">
</style>
